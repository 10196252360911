<template>
  <div>
    <b-container>
      <b-row>
        <b-col md="1"></b-col>
        <b-col md="11">
          <h3 class="heading">FAQ</h3>
          <div class="faq-maindv mt-4" v-if="faqData">
            <div v-for="(item, i) in faqData" :key="i">
              <div class="mb-3" v-for="(each, id) in item" :key="id">
                <div class="d-flex justify-content-between question-background">
                  <p class="question mb-0">Q: {{ each.topic }}</p>
                  <b-icon
                    stacked
                    icon="chevron-right"
                    shift-h="0"
                    id="arrow-down-black"
                    @click="isFindIndex(each._id, faqData)"
                    :value="each._id"
                    v-show="each._id !== open"
                    scale="0.5"
                  ></b-icon>
                  <b-icon
                    stacked
                    icon="chevron-down"
                    shift-h="0"
                    id="arrow-down"
                    @click="open = false"
                    v-show="each._id === open"
                    scale="0.5"
                  ></b-icon>
                </div>
                <p class="answer" v-show="each._id === open">
                  {{ each.description }}
                </p>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "my-order",
  data() {
    return {
      isLoading: false,
      open: "",
    };
  },
  computed: {
    ...mapState({
      faqData: (state) => state.profile.faqData,
    }),
  },
  methods: {
    isFindIndex(value, faqData) {
      const result = faqData.map((each) => each.map((each) => each._id));
      let list = [];
      result.map((eachArray) => eachArray.map((each) => list.push(each)));
      if (list.map((each) => each === value)) {
        this.open = value;
      } else {
        this.open = false;
      }
    },
  },
  async mounted() {
    await this.$store.dispatch("profile/fetchFaqData");
  },
};
</script>

<style scope>
#arrow-down-black {
  background: #272727;
  color: #fff;
  cursor: pointer;
  border-top-right-radius: 8px;
  font-size: 18px;
  height: 57px;
}
#arrow-down {
  background: #ff6a2e;
  color: #fff;
  cursor: pointer;
  border-top-right-radius: 8px;
  height: 57px;
}
.heading {
  color: #ff6a2e;

  font-weight: 800;
}
.question {
  font-style: normal;
  font-weight: 400;
  padding: 16px 16px;
}

.question-background {
  margin-bottom: 0;
  background: #f8f8f8;
  border: 1px solid #e8e8e8;
  border-radius: 8px 8px 0px 0px;
}

.answer {
  font-style: normal;
  font-weight: 400;
  color: #272727;
  background: #fafafa;
  border: 1px solid #e8e8e8;
  padding: 16px;
}
@media (max-width: 768px) {
  .question {
    padding: 6px 16px;
    font-size: 13px;
  }
  #arrow-down-black {
    font-size: 18px;
    height: 52px;
  }
  .answer {
    font-size: 14px;
  }
}
</style>
